<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="primary"
    >
      {{ alertmessage }}
      <v-btn
        color="white"
        text
        @click="editUserRedirect(); snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-row justify="center pb-16">
      <v-col
        cols="12"
        sm="8"
        md="8"
      >
        <base-material-card
          icon="mdi-account-outline"
          color="blanketbutton"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2 grey--text lighten-2">
              Edit Profile
              <span class="text-body-1">— Complete your profile</span>
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  align-self="start"
                >
                  <H3 class="ma-5">
                    ABOUT
                  </H3>
                  <span class="ma-50 grey--text lighten-2">Full Name</span>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="alpha|required"
                  >
                    <v-text-field
                      v-model="currentuser.firstname"
                      outlined
                      label="First Name"
                      class="purple-input grey--text lighten-2"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="alpha|required"
                  >
                    <v-text-field
                      v-model="currentuser.lastname"
                      outlined
                      label="Last Name"
                      class="purple-input grey--text lighten-2"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                >
                  <div
                    class="mb-10 d-flex"
                  >
                    <validation-provider
                      name="Gender"
                      rules="required"
                    >
                      <v-btn-toggle
                        v-model="currentuser.gender"
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          value="M"
                          class="font-weight-bold"
                        >
                          <v-icon
                            left
                            color="secondary"
                          >
                            mdi-gender-male
                          </v-icon>
                          Male
                        </v-btn>

                        <v-btn
                          color="blanketbutton"
                          text
                          value="F"
                          class="font-weight-bold"
                        >
                          <v-icon
                            left
                            color="secondary"
                          >
                            mdi-gender-female
                          </v-icon>
                          Female
                        </v-btn>
                      </v-btn-toggle>
                    </validation-provider>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Day"
                    rules="required"
                  >
                    <div
                      class="mb-3 grey--text lighten-2"
                    >
                      Birthday
                    </div>
                    <p>Date of birth</p>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Day"
                          rules="required"
                        >
                          <v-select
                            v-model="bdays"
                            :items="daysavailable"
                            label="Day"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="calcBday()"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"

                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Month"
                          rules="required"
                        >
                          <v-select
                            v-model="bmonths"
                            :items="monthsavailable"
                            item-text="state"
                            item-value="abbr"
                            label="Month"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="calcBday()"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"

                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Year"
                          rules="required"
                        >
                          <v-select
                            v-model="byears"
                            :items="yearsavailable"
                            label="Year"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="calcBday()"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </validation-provider>
                  <div
                    class="d-flex justify-space-between"
                  >
                    <span class="grey--text lighten-2 justify-space-between"> Height</span>
                    <v-btn-toggle
                      v-model="unitsheight"
                      class="mb-4"
                      mandatory
                    >
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        max-height="1"
                        max-width="1"
                        value="metric"
                        @change="units === 'metric'"
                      >
                        CM
                      </v-btn>
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        max-height="1"
                        max-width="1"
                        value="standard"
                        @change="units === 'standard'"
                      >
                        INCHES
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                  <div v-if="unitsheight === 'metric'">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Height"
                        rules="required|numeric"
                      >
                        <v-text-field
                          v-model="currentuser.height.centimeters"
                          label="Centimeters"
                          outlined
                          color="primary"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </div>
                  <div
                    v-if="unitsheight === 'standard'"
                  >
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="pa-0 ma-0"
                    >
                      <div class="d-flex justify-space-between">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Height Feet"
                          rules="required|numeric|digits:1|"
                        >
                          <v-text-field
                            v-model="currentuser.height.feet"
                            label="Height Feet"
                            outlined
                            color="primary"
                            :error-messages="errors"
                          />
                        </validation-provider>
                        <v-spacer></v-spacer>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Height Inches"
                          rules="required|numeric|min_value:0|max_value:12|"
                        >
                          <v-text-field
                            v-model="currentuser.height.inches"
                            label="Inches"
                            outlined
                            color="primary"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </div>
                    </v-col>
                  </div>
                  <div
                    class="d-flex justify-space-between"
                  >
                    <span class="grey--text lighten-2 justify-space-between"> Weight</span>
                    <v-btn-toggle
                      v-model="unitsweight"
                      class="mb-4"
                      mandatory
                    >
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        max-height="1"
                        max-width="1"
                        value="metric"
                        @change="units === 'metric'"
                      >
                        KG
                      </v-btn>
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        max-height="1"
                        max-width="1"
                        value="standard"
                        @change="units === 'standard'"
                      >
                        Pounds
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                  <div v-if="unitsweight === 'metric'">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Weight"
                        rules="numeric|max_value:600|"
                      >
                        <v-text-field
                          v-model="currentuser.weight.kg"
                          label="Kilograms"
                          outlined
                          color="primary"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </div>
                  <div
                    v-if="unitsweight === 'standard'"
                  >
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Weight LBs"
                        rules="numeric|max_value:600|"
                      >
                        <v-text-field
                          v-model="currentuser.weight.lb"
                          label="Pounds"
                          outlined
                          color="primary"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </div>
                  <div class="mb-3 grey--text lighten-2">
                    Social Security Number
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|digits:9"
                  >
                    <v-text-field
                      v-model="currentuser.ssn"
                      v-mask="'#########'"
                      :error-messages="errors"
                      label="SSN"
                      outlined
                    >
                    </v-text-field>
                  </validation-provider>
                  <div class="mb-3 grey--text lighten-2">
                    Drivers License
                  </div>

                  <v-text-field
                    v-model="currentuser.dlNumber"
                    label="Drivers License Number"
                    class="purple-input mb-3"
                    outlined
                  />
                  <div class="mb-3 grey--text lighten-2">
                    Drivers License State
                  </div>
                  <validation-provider>
                    <v-text-field
                      v-model="currentuser.dlState"
                      label="Drivers License State"
                      class="purple-input mb-3"
                      outlined
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                >
                  <h2 class="ma-5 mt-n5">
                    Contact
                  </h2>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="pa-0 ma-0"
                  >
                    <div class="mb-3 grey--text lighten-2">
                      Mobile Number
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone Number"
                      rules="required|digits:10"
                    >
                      <v-text-field
                        v-model="currentuser.mobile"
                        v-mask="'###########'"
                        label="Mobile Number"
                        class="purple-input mb-3 grey--text lighten-2"
                        outlined
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <div class="mb-3 grey--text lighten-2">
                      Email
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <v-text-field
                        outlined
                        :label="currentuser.email"
                        class="purple-input mb-3"
                        disabled
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <vuetify-google-autocomplete
                    id="map"
                    ref="address"
                    classname="form-control mb-n1 grey--text lighten-2"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getAddressData"
                  >
                  </vuetify-google-autocomplete>
                  <div class="mb-3 grey--text lighten-2">
                    Address
                  </div>
                  <validation-provider>
                    <v-text-field
                      v-model="currentuser.address"
                      disabled
                      label="Address"
                      outlined
                      class="purple-input mb-n5 grey--text lighten-2"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                >
                  <validation-provider>
                    <v-text-field
                      v-model="currentuser.city"
                      disabled
                      outlined
                      label="City"
                      class="purple-input"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider>
                    <v-text-field
                      v-model="currentuser.country"
                      disabled
                      outlined
                      label="Country"
                      class="purple-input"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider>
                    <v-text-field
                      v-model="currentuser.postcode"
                      disabled
                      outlined
                      class="purple-input"
                      label="Post Code"
                      type="number"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-btn
                    color="blanketbutton"
                    class="mr-0"
                    @click="updateuser"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Userprofile',
    data () {
      return {
        snackbar: false,
        timeout: 5000,
        date: null,
        menu: false,
        unitsheight: 'standard',
        unitsweight: 'standard',
        units: 'standard',
        pickerDate: '',
        bday: '',
        bmonth: '',
        byear: '',
        bdays: '',
        bmonths: '',
        byears: '',
        loading: '',
        alertmessage: '',
        centimeters: '',
        dlState: '',
        dlNumber: '',
        currentuser: {
          email: '',
          ssn: '',
          dob: '',
          gender: '',
          weight: {
            kg: '',
            lb: ''
          },
          height: {
            feet: '',
            inches: '',
            cm: ''
          },
          driverslicense: {
            dlState: '',
            dlNumber: ''
          },
          id: '',
          alerts: '',
          status: '',
          firstname: 'unset',
          lastname: 'unset',
          mobile: 'unset',
          address: 'unset',
          city: 'unset',
          country: 'USA',
          postcode: 'unset',
        },
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        }
      }
    },
    computed: {
      yearsavailable () {
        let maxyear = Number(moment().format('YYYY')) - 18
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i <= maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
    },
    created () {
      this.currentuser.height.feet = 0
      this.currentuser.height.inches = 0
      this.currentuser.dlNumber = ''
      this.currentuser.dlState = ''
      this.currentuser.gender = ''
      this.currentuser.weight = {
        lb: '',
        kg: ''
      }
      this.currentuser = this.$store.getters.getuser
      this.$gtag.pageview('UserProfile')
    },
    methods: {
      getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData
        console.log('google address ', this.address)
        this.currentuser.postcode = addressData.postal_code
        this.currentuser.city = addressData.locality
        this.currentuser.address = addressData.name
        this.currentuser.country = addressData.country
      },
      editUserRedirect () {
        return this.$router.push('userprofile')
      },
      updatePassed (passed) {
        passed = !passed
        console.log(passed)
      },
      calcBday () {
        if (this.byears && this.bmonths && this.bdays) {
          let monthNumber = moment().month(this.bmonth_i)
          let date = moment(this.byears + '-' + monthNumber + '-' + this.bdays, 'YYYY-M-DD')
          this.currentuser.dob = date._i
        }
      },
      updateuser () {
        this.$gtag.event('UserProfileUpdated', { method: 'Updated', campaign: 'none' })
        this.$store.dispatch('updateUserProfile', this.currentuser)
        this.alertmessage = 'Profile has been updated'
        this.snackbar = true
      }
    },
  }
</script>
